import React from "react"

import { useEffect} from "react"

import Layout from "../components/Layout"

import SliderSection from "../components/SliderSection"

import ContentSection from "../components/ContentSection"

import Column from "../components/Column"

import ContactSection from "../components/ContactSection"

import Slide from "../components/Slide"

export default function Usluga( props) {
	
	useEffect( () => {
		
		let uslugeMenu = null
		
		if( ( uslugeMenu = document.getElementById( "usluge")) !== null)
			uslugeMenu.classList.add( "kn-active")
	},[])
	
	return (
		<Layout>
			
			<SliderSection>
				<Slide width="100%" activeSlide={ 0} index={ 0} backgroundImage={ "https://geokontura.dev.koona.tech/" + props.pageContext.featuredImage} >
					<h3 className="kn-slide-title">{ props.pageContext.title}</h3>
				</Slide>
			</SliderSection>
			
			{ 
				props.pageContext.content.map( ( section, index) => 
					<ContentSection background={ section.background} className={ section.className}>
						{
							section.columns.map( ( column, index2) => <Column width={ column.width} background={ column.background} className={ column.className} introText={ column.innerHTML} /> )
						}
					</ContentSection>
				)
			}
			<ContentSection>
				<Column width="50%" className="kn-usluga-column-text">
					
				</Column>
			</ContentSection>
			
			<ContactSection id="kontakt" />
		</Layout>
	)
}
