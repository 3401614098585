import React from "react"

import "../styles/column.css"

export default function Column({ className, background, width, introText, children}) {
	
	return (
		<>
			{ introText ?
				<div style={ { width: width, background: background}} className={`kn-column-content ${ className}`} dangerouslySetInnerHTML={ { __html: introText}} />
				: 
				<div style={ { width: width, background: background}} className={`kn-column-content ${ className}`} >
					{ children}
				</div>
			}
		</>	
	)
}